import React from 'react'
import {getTourEvents, getTourObjects} from "../api";
import {Preloader} from "../components/Preloader";
import {Place} from "./Place";

class TourEvents extends React.Component{
    constructor(props){
        super(props);
        this.state = {tourEvents:[], message: ''}
    }
    componentDidMount() {
        getTourEvents().then((data)=>{
            if(data.message!='По данному запросу данных не найдено'){
            this.setState({
                tourEvents: data
            });} else{
                this.setState({
                    message: data.message
                })
            }
            console.log(this.state.message);
        });
        this.initState();
        console.log(this.state.tourEvents);
    }
    initState(){
        this.setState(getTourEvents().then((data) => {
            this.setState({
                places : data})
        }));
    }
    componentWillUnmount() {
        this.setState({
            tourEvents: null
        })
    }

    render(){
        const {tourEvents, message} = this.state;

        return (<div>
                <div className="row my_grid">
                    <div className="col s12 m8 l9">
                        {!tourEvents.length ? (
                                message == '' ? (
                                    <Preloader></Preloader>) : (
                                    <div>{message}</div>
                                )

                        ) : (
                            <div className="list">
                                {tourEvents.map((tourEvent)=>(
                                    <div>TourEvent</div>

                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }
}

export {TourEvents}