import './App.css';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import {Navbar} from "./components/Navbar";
import {Home} from "./components/Home";
import {PlacesPage} from "./pages/PlacesPage";
import {TourEvents} from "./class_components/TourEvents";
import {EmployeesList} from "./components/EmployeesList";
import {Profile} from "./components/Profile";
import {Footer} from "./components/Footer";
import {AuthorizationPage} from "./pages/AuthorizationPage";
import {AccountPage} from "./pages/AccountPage";
import RequireAuth from './components/RequireAuth';


function App() {
  return (
      <>
          <div className="row">
              <div className="grid-example col s12">
                      <Navbar/>
              </div>
                  <div className="grid-example col s12">
                      <Routes>
                          <Route path="/" element={<Home/>}></Route>
                          <Route path="/places" element={<RequireAuth><PlacesPage/></RequireAuth>}></Route>
                          <Route path="/events" element={<RequireAuth><TourEvents/></RequireAuth>}></Route>
                          <Route path="/employees" element={<EmployeesList/>}/>
                          <Route path="/profile" element={<Profile/>}/>
                          <Route path="/login" element={<AuthorizationPage/>}/>
                          <Route path="/account" element={<AccountPage/>}/>
                      </Routes>
                  </div>
              <div className="grid-example col s12" >
                  <Footer/>
              </div>
          </div>
    </>

  );
}


export default App;
