import React from "react";
import {getTourObjectById, getLogoforTourObject} from "../api";
import {Preloader} from "../components/Preloader";

class Place extends React.Component{
    constructor(props){
        super(props);
        this.state = {id: props.id, place: {}};
    }
    componentDidMount() {
        console.log(this.state.id);
        getTourObjectById(this.state.id).then((data) => {
                this.setState({place:data})
            });
        getLogoforTourObject(this.state.id).then((data) => {
                this.setState({logo:data})
        });
        //console.log(this.state.place)
    }
    componentWillUnmount() {
        this.setState(null);
    }
    render(){
        const {place, logo} = this.state;
        console.log(logo)
        return (<div>
            {place==null ? <Preloader/> :(
                <div className="col s12 m7">
                    <h2 className="header">{place.name}</h2>
                    <div className="card horizontal">
                        <div className="card-image">

                        </div>
                        <div className="card-stacked">
                            <div className="card-content">
                                <p>{place.description}</p>
                            </div>
                            <div className="card-action">
                                <a href="#">Подробнее</a>
                            </div>
                        </div>
                    </div>
                </div>
            )}

        </div>)
    }
}
export {Place};
