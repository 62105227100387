function Home(){
    return (
        <div className="row">

            <div className="col s4">
                <div>
                    <img src=''/>
                </div>
                <div>
                    Promo Content 1 goes here
                </div>
            </div>
            <div className="col s4">
                <div>
                    <img src=''/>
                </div>
                <div>
                    Promo Content 2 goes here
                </div>
            </div>
            <div className="col s4">
                <div>
                    <img src=''/>
                </div>
                <div>
                    Promo Content 3 goes here
                </div>
            </div>

        </div>
    )
}
export {Home};