import React, {useState,useEffect} from "react";
import {authorizeUser} from './../api.js'
import {useLocation, useNavigate} from "react-router-dom";


function Login(props){
    let navigate = useNavigate();
    let location = useLocation();
    var [path, setPath] = useState(null);
        console.log(location);
    const onHandleAuth = (event: React.FormEvent<HTMLFormElement>) =>{
        event.preventDefault();
        let formData = new FormData(event.currentTarget);
        let login = formData.get("auth_email");
        let pass = formData.get("auth_pass");
        let result = authorizeUser(login, pass);
        console.log(location.state.from.replace);
        setPath(location.state.from.pathname);
        navigate("/profile");
    };


    return (<div>
            <form action="#" method="post" onSubmit={onHandleAuth}>
                <label>Введите Ваш емейл</label>
                <input name="auth_email" placeholder="Введите Ваш емейл" required/>
                <label>Введите Ваш пароль</label>
                <input type="password" name="auth_pass" placeholder="Введите пароль" required/>
                <button className="form_auth_button" type="submit" name="form_auth_submit" >Войти</button>
            </form>
        </div>);

}

export {Login};