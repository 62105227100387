import React from 'react'
import {getTourObjects} from '../api.js';
import {Preloader} from "../components/Preloader";
import {Place} from "./Place";

class Places extends React.Component{
    constructor(props){
        super(props);
        this.state = {places:[]};
    }
    componentDidMount() {
        this.initState();
    }
    initState(){
        this.setState(getTourObjects().then((data) => {
            this.setState({
                places : data})
        }));
    }
    componentWillUnmount() {
        this.setState({places:[]})
    }

    render(){
        const {places} = this.state;
        return (<div>
            <div className="row my_grid">
                <div className="col s12 m8 l9">
                    {!places.length ? (
                        <Preloader></Preloader>
                    ) : (
                        <div className="list">
                            {this.state['places'].map((place)=>(
                                <Place id={place.id} key={place.id}/>

                            ))}
                        </div>
                    )}
                </div>
            </div>
            </div>)
    }
}
export {Places};