import logo from '../logo.png';
import {Link} from "react-router-dom";
function Navbar(){
    return(
        <div>
            <nav>
                <div className="nav-wrapper grey">
                    <Link to="/" className="brand-logo">
                        <img src={logo} height="60px"/>
                        <span className="panel">Гид48 - Административная панель</span></Link>
                    <ul className="right">
                        <li><Link className="" to="/places"><i className="material-icons">my_location</i></Link></li>
                        <li><Link className="" to="/events"><i className="material-icons">event</i></Link></li>
                        <li><Link className="" to="/employees"><i className="material-icons">people</i></Link></li>
                        <li><Link className="" to="/profile"><i className="material-icons">person</i></Link></li>
                    </ul>
                </div>
            </nav>
        </div>
    )
}

export {Navbar};

