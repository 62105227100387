import {isAuthorized} from './gid_functions/auth_functions'
const API_URL='https://api.gid-48.ru/';

const getTourObjects = async() => {
    const response = await fetch(API_URL+'object/get_objects.php');
    return await response.json();
}

const searchTourObjects = async(name) => {
    const response = await fetch (API_URL + 'object/search.php?name='+name);
    return await response.json();
}
const getTourObjectsCategory = async () => {
    const response = await fetch(API_URL + 'object/getObjectsCategory.php');
    return await response.json();
}
const getTourObjectById=async(id)=>{
    const urlforlog = API_URL+'object/get_object.php?id='+id;
    const response = await fetch(API_URL+'object/get_object.php?id='+id);
    return await response.json();
}

const getLogoforTourObject=async (tourId)=>{
    const urlforlog = API_URL+'object/logo.php?id='+tourId;
    const response = await fetch(API_URL + '/object/logo.php?id='+tourId);
    return await response.json();
}

const getTourEvents = async()=>{
    const response = await fetch (API_URL+'event/get_events.php');
    //console.log(API_URL+'event/get_events.php');
    var jsonText=response.json();
    console.log(jsonText);
    return jsonText;
}
const getTourEventById = async(id) =>{
    const response = await fetch (API_URL+'event/get_event.php?id='+id);
    return response.json();
}

const authorizeUser= async(login, pass)=>{
    let user = {
        "login": login,
        "pass": pass
    }
    let formData = new FormData();
    formData['login']= login;
    formData['pass']= pass;
    console.log(user);
    let response = await fetch('https://api.gid-48.ru/user/auth.php',{
            method: 'POST',
            headers:{
                'Content-Type':'application/json'
            },
            body: JSON.stringify(user)
        })
    let result = await response.json();
    console.log(result)
    document.cookie = "token = " + result['access'];
    document.cookie = "refresh = " + result['refresh'];

    return result
}

export {getTourObjects, searchTourObjects,
    getTourObjectsCategory, getTourObjectById, getTourEvents, getTourEventById,
    authorizeUser, getLogoforTourObject};