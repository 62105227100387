
import {useLocation, Navigate} from "react-router-dom";
import {useAuth} from "../hooks/Auth";

export default function RequireAuth({children}: { children: JSX.Element }) {
    let token = useAuth();
    let location = useLocation();
    console.log(location);

    if(token==null){
        return <Navigate to="/login" state={{from: location}} replace/>;
    } else {
        return children;
    }
}