import {Place} from "./Place";

function EmployeesList(){
    return (
        <div className="row">

            <div className="col s3">
                List of employees
            </div>

            <div className="col s9">
                Сотрудник
            </div>

        </div>
    )
}
export {EmployeesList};