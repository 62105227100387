import React from "react";
import {Login} from "../components/Login";

function AuthorizationPage(props){
    return (
        <div>
            <Login />
        </div>
    )
}

export {AuthorizationPage};