function Footer(){
    return (
        <footer className="page-footer myfooter grey">
            <div className="footer-copyright">
                <div className="container">
                    © 2021 ИП Лысиков В.А.
                    <a className="grey-text text-lighten-4 right" href="#!">More Links</a>
                </div>
            </div>
        </footer>
    )
}

export {Footer};